var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import find from 'lodash/find';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Button from 'components/atoms/Button';
import RenewalPolicyDocuments from 'components/organisms/RenewalPolicyDocuments';
import { TotalPriceBanner } from 'components/organisms/QuoteSummary';
import RenewalJourneyProgress from 'components/organisms/RenewalJourneyProgress';
import RenewalYourCover from 'components/organisms/RenewalYourCover';
import RenewalAnnualPaymentForm from 'components/organisms/RenewalAnnualPaymentForm';
import SagePayIframe from 'components/organisms/SagePayIframe';
import { getClassOfUseDisplay, getDriverDisplayValuesForList } from 'guidewirePolicyUtils';
import { getPageContent, mtaTokenRefresh, addBillingDetailsAndGetSagePay, bindRenewalPayment } from 'services';
import { ContentPageURL, SessionStorageKey } from 'types';
import { TagManagerArgs } from 'analytics';
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ButtonsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 40px 0;\n\n  button {\n    margin-left: 10px;\n  }\n"])));
var RenewalPaymentSummary = function (_a) {
    var renewalState = _a.renewalState, renewalPolicy = _a.renewalPolicy, quoteDetails = _a.quoteDetails, autoRenew = _a.autoRenew;
    var history = useHistory();
    var _b = useState(autoRenew), userAutoRenew = _b[0], setUserAutoRenew = _b[1];
    var _c = useState(undefined), cmsContent = _c[0], setCmsContent = _c[1];
    var _d = useState(undefined), policyDocumantsContent = _d[0], setPolicyDocumantsContent = _d[1];
    var _e = useState(undefined), yourCoverContent = _e[0], setYouCoverContent = _e[1];
    var _f = useState(undefined), renewalInformation = _f[0], setRenewalInformation = _f[1];
    var _g = useState(undefined), annualBankDetails = _g[0], setAnnualBankDetails = _g[1];
    var _h = useState(false), registeredHasBeenSelected = _h[0], setRegisteredHasBeenSelected = _h[1];
    var _j = useState(undefined), sagePayLink = _j[0], setSagePayLink = _j[1];
    var defactoDate = moment('20230601');
    // Account holder (about you)
    var accHolder = quoteDetails.accountHolder;
    var accHolderDisplay = getDriverDisplayValuesForList(accHolder);
    var accHolderDropDown = {
        driverPolicyHolder: accHolderDisplay.displayName,
        driverDateOfBirth: accHolderDisplay.dateOfBirth,
        driverOccupation: accHolderDisplay.occupation,
        overnightParkingAddressDisplayName: accHolderDisplay.primaryAddress.displayName,
        driverHomeOwner: accHolderDisplay.homeOwner,
    };
    var drivers = quoteDetails.drivers;
    var diversValues = drivers.map(function (item) {
        return getDriverDisplayValuesForList(item);
    });
    var coverDropDownValues = {
        periodStartDate: moment(quoteDetails.baseData.periodStartDate).format('DD / MM / YYYY'),
        periodEndDate: moment(quoteDetails.baseData.periodEndDate).format('DD / MM / YYYY'),
        coverType: renewalPolicy.coverType,
        classOfUse: getClassOfUseDisplay(quoteDetails.vehicles[0].classOfUse),
        policyProductMilesDisplay: renewalState.paymentPlan.miles + " miles",
        keyCover: 'Included as standard',
    };
    // bc:5 or bc:7
    // bc:5 => shows the RenewalAnnualPaymentForm component
    // bc:7 => redirects to the Direct debit screens
    var paymentType = renewalState.paymentPlan.billingID;
    var _k = useForm({
        mode: 'onChange',
        defaultValues: {
            addressLine1: quoteDetails.baseData.policyAddress.addressLine1,
            addressLine2: quoteDetails.baseData.policyAddress.addressLine2,
            city: quoteDetails.baseData.policyAddress.city,
            county: quoteDetails.baseData.policyAddress.county,
            postalCode: quoteDetails.baseData.policyAddress.postalCode,
        },
    }), register = _k.register, handleSubmit = _k.handleSubmit, errors = _k.errors;
    // Annual payments handler only
    var bindPayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        selectedQuote: renewalState.paymentPlan.id,
                        selectedPaymentPlan: 'bc:5',
                        autoRenew: userAutoRenew,
                    };
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, bindRenewalPayment(policyNumber, data, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        history.push('/payment-complete');
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        bindPayment();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // Annual payments only
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var paymentData, policyNumber, token, quote, refresh;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    paymentData = {
                        selectedQuote: renewalState.paymentPlan.id,
                        selectedPaymentPlan: 'bc:5',
                        billingAddress: {
                            addressLine1: data.addressLine1,
                            addressLine2: data.addressLine2,
                            city: data.city,
                            county: data.county,
                            postalCode: data.postalCode,
                        },
                        contactEmail: quoteDetails.bindData.contactEmail,
                        documentationPreference: 'Electronic',
                        paymentDetails: {
                            creditCardData: {
                                isCardRegisteredToYou: true,
                            },
                        },
                        applicationType: 'SFE_ITB',
                        autoRenew: userAutoRenew,
                    };
                    policyNumber = renewalPolicy.policyNumber.replace('ITB/', '');
                    token = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
                    return [4 /*yield*/, addBillingDetailsAndGetSagePay(policyNumber, paymentData, token)];
                case 1:
                    quote = _a.sent();
                    if (quote.status === 200) {
                        setSagePayLink(quote.data.bindData.sagePayURL);
                    }
                    if ((quote.status !== undefined && quote.status === 500) ||
                        (quote.data.status !== undefined && quote.data.status === 500)) {
                        console.log(500);
                    }
                    if (!(quote.data.status === 401)) return [3 /*break*/, 3];
                    return [4 /*yield*/, mtaTokenRefresh()];
                case 2:
                    refresh = _a.sent();
                    if (refresh === 200)
                        onSubmit(data);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var selected = quoteDetails.includedAddOns.map(function (item) {
        var rrLevel = undefined;
        if (item.codeIdentifier === 'PMRoadRescueCov') {
            rrLevel = item.terms[0].chosenTerm;
        }
        return __assign({ id: item.codeIdentifier, selected: item.selected }, (rrLevel && { rrLevel: rrLevel }));
    });
    var getDocumentLinks = function (cmsContent, id) {
        var links = cmsContent.contentSections.filter(function (item) { return item.sectionId === id; });
        return links[0].body;
    };
    var getRoadRescueDocumentLink = function (cmsContent) {
        var level1or2 = getDocumentLinks(cmsContent, 'roadRescueDocumentOneTwo');
        var level3 = getDocumentLinks(cmsContent, 'roadRescueDocumentThree');
        var rrLevel = '';
        selected.map(function (item) {
            if (item.id === 'PMRoadRescueCov' && item.selected === true) {
                rrLevel = item.rrLevel;
            }
        });
        if (rrLevel === 'Level1' || rrLevel === 'Level2')
            return level1or2;
        if (rrLevel === 'Level3')
            return level3;
        return rrLevel;
    };
    var getKeyProtectDocumentLink = function (cmsContent) {
        var link = getDocumentLinks(cmsContent, 'keyProtectDocument');
        var kp = false;
        selected.map(function (item) {
            if (moment(quoteDetails.baseData.periodStartDate).isSameOrAfter(defactoDate)) {
                if (item.id === 'PMKeyProtect') {
                    kp = true;
                }
            }
            else {
                if (item.id === 'PMKeyProtect' && item.selected === true) {
                    kp = true;
                }
            }
        });
        return kp ? link : '';
    };
    useEffect(function () {
        if (cmsContent === undefined && paymentType === 'bc:7') {
            getPageContent(ContentPageURL.RENEWAL_PAYMENT_SUMMARY_MONTHLY).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        }
        if (cmsContent === undefined && paymentType === 'bc:5') {
            getPageContent(ContentPageURL.RENEWAL_PAYMENT_SUMMARY_ANNUAL).then(function (content) {
                var payload = content.payload;
                setCmsContent(payload);
            });
        }
        if (cmsContent !== undefined) {
            if (policyDocumantsContent === undefined) {
                var results = cmsContent.contentSections.filter(function (item) {
                    return item.sectionId === 'policyDocumentsText' ||
                        item.sectionId === 'documentLinks' ||
                        item.sectionId === 'documentsPreference';
                });
                setPolicyDocumantsContent(results);
            }
            if (yourCoverContent === undefined) {
                var result = cmsContent.contentSections.filter(function (item) { return item.cmsTitle === 'Your Cover Group'; });
                setYouCoverContent(result[0].formGroups);
            }
            // Form - bank details for bc:5
            if (paymentType === 'bc:5' && renewalInformation == undefined) {
                var autoRenewalWarning = find(cmsContent.contentSections, function (obj) {
                    return obj.sectionId === 'autoRenewalText';
                });
                setRenewalInformation(autoRenewalWarning);
                cmsContent.contentSections.map(function (item) {
                    if (item.formGroups && item.cmsTitle.includes('Payment'))
                        setAnnualBankDetails(item.formGroups);
                });
            }
        }
        var handler = function (event) {
            try {
                if (event.data === 'scrollToTop') {
                    setTimeout(function () {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }, 5000);
                }
                var data = JSON.parse(event.data);
                if (data.message === 'success') {
                    bindPayment();
                }
                if (data.message === 'failure')
                    history.push('/error');
            }
            catch (error) {
                // stops error messaging filling the console when it tries to parse storybook messages
            }
        };
        if (location.pathname === '/renewal-payment-summary') {
            window.addEventListener('message', handler);
        }
    }, [cmsContent]);
    return (React.createElement(Root, null,
        cmsContent && sagePayLink === undefined && (React.createElement(React.Fragment, null,
            React.createElement(RenewalJourneyProgress, { step: 4 }),
            React.createElement(TotalPriceBanner, { title: cmsContent.header.title, price: renewalState.paymentPlan.total.toFixed(2) }),
            policyDocumantsContent && (React.createElement(RenewalPolicyDocuments, { content: policyDocumantsContent, standardDocs: getDocumentLinks(cmsContent, moment(renewalPolicy.effective.substring(0, 10)).isBefore('2022-12-20')
                    ? 'documentsStandard_0722'
                    : 'documentsStandard_1222'), roadRescueDoc: getRoadRescueDocumentLink(cmsContent), keyProtectDoc: getKeyProtectDocumentLink(cmsContent) })),
            yourCoverContent && selected && (React.createElement(RenewalYourCover, { content: yourCoverContent, accHolderDropDown: accHolderDropDown, driversValues: diversValues, coverDropDownValues: coverDropDownValues, selectedCover: selected, showBreakdown: quoteDetails.includedAddOns.find(function (item) { return item.codeIdentifier === 'PMRoadRescueCov'; }) === undefined
                    ? false
                    : true, renewalEffectiveDate: moment(renewalPolicy.effective) })),
            renewalInformation && annualBankDetails && (React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(RenewalAnnualPaymentForm, { renewalInformation: renewalInformation, annualBankDetails: annualBankDetails, register: register, setRegisteredHasBeenSelected: function (value) { return setRegisteredHasBeenSelected(value); }, errors: errors, autoRenew: userAutoRenew, onUserAutoRenew: function (opt) { return setUserAutoRenew(opt); } }),
                React.createElement(ButtonsWrapper, null,
                    React.createElement(Button, { type: "button", buttonStyle: "secondary", onClick: function () {
                            history.goBack();
                            TagManagerArgs('Button', window.location.pathname + " - Renewal Payment Summary (Annual)", 'Go Back');
                        } }, "Go back"),
                    React.createElement(Button, { type: "submit", disabled: !registeredHasBeenSelected }, "Continue")))),
            !renewalInformation && !annualBankDetails && (React.createElement(ButtonsWrapper, null,
                React.createElement(Button, { buttonStyle: "secondary", onClick: function () {
                        history.goBack();
                        TagManagerArgs('Button', window.location.pathname + " - Renewal Payment Summary (Monthly)", 'Go Back');
                    } }, "Go back"),
                React.createElement(Button, { onClick: function () {
                        history.push('/renewal-monthly-details');
                        TagManagerArgs('Button', "" + window.location.pathname, 'Continue');
                    } }, "Continue"))))),
        sagePayLink !== undefined && React.createElement(SagePayIframe, { sagePayLink: sagePayLink })));
};
var mapStateToProps = function (state) { return ({
    renewalState: state.renewalState,
    renewalPolicy: state.renewalPolicy,
    quoteDetails: state.renewalAmendedQuote,
    autoRenew: state.autoRenew,
}); };
export default connect(mapStateToProps)(RenewalPaymentSummary);
var templateObject_1, templateObject_2;
