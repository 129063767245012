import React from 'react';
import { getSingleFormGroupContent, getSingleFieldContent, Group, FormInput, FormSelect, FormRadio, FormDatePicker, FormAutoComplete, FormDateYearMonthOnly, } from 'components/organisms/Form';
import { getGwDropdownValues } from 'guidewirePolicyUtils';
var yourTitleOptions = [
    {
        label: 'Mr.',
        value: 'mr',
    },
    {
        label: 'Mrs.',
        value: 'mrs',
    },
    {
        label: 'Miss',
        value: 'miss',
    },
    {
        label: 'Ms.',
        value: 'ms',
    },
    {
        label: 'Dr.',
        value: 'dr',
    },
    {
        label: 'Mx.',
        value: 'mx_itb',
    },
];
var AddDriverNewDriver = function (_a) {
    var formGroups = _a.formGroups, register = _a.register, control = _a.control, watchedFields = _a.watchedFields, errors = _a.errors;
    var newDriverContent = getSingleFormGroupContent('newDriverSection', formGroups);
    var title = getSingleFieldContent('title', newDriverContent.fields);
    var titleOptions = yourTitleOptions;
    var firstName = getSingleFieldContent('firstName', newDriverContent.fields);
    var lastName = getSingleFieldContent('lastname', newDriverContent.fields);
    var gender = getSingleFieldContent('gender', newDriverContent.fields);
    var genderOptions = getGwDropdownValues(gender.gwTypelist);
    var dateOfBirth = getSingleFieldContent('dob', newDriverContent.fields);
    var occupation = getSingleFieldContent('occupation', newDriverContent.fields);
    var occupationOptions = getGwDropdownValues(occupation.gwTypelist);
    var maritalStatus = getSingleFieldContent('maritalStatus', newDriverContent.fields);
    var maritalStatusOptions = getGwDropdownValues(maritalStatus.gwTypelist);
    var phRelationship = getSingleFieldContent('phRelationship', newDriverContent.fields);
    var phRelationshipOptions = getGwDropdownValues(phRelationship.gwTypelist);
    var ownYourOwnHome = getSingleFieldContent('ownYourOwnHome', newDriverContent.fields);
    var ukResident = getSingleFieldContent('ukResident', newDriverContent.fields);
    var ukResidentSince = getSingleFieldContent('ukResidentSince', newDriverContent.fields);
    return (React.createElement(Group, { title: newDriverContent.title },
        React.createElement(FormSelect, { label: title.label, options: titleOptions, toolTip: title.toolTip, placeholder: title.placeholder, name: title.value, control: control, spanTwoCols: title.spanTwoColumns, additionalToTooltip: title.additionalToTooltip, isRequired: title.required, error: errors && errors[title.value] ? errors[title.value] : undefined }),
        React.createElement(FormInput, { type: "text", label: firstName.label, name: firstName.value, toolTip: firstName.toolTip, control: register, placeholder: firstName.placeholder, additionalToTooltip: firstName.additionalToTooltip, isRequired: firstName.required, error: errors && errors[firstName.value] ? errors[firstName.value] : undefined }),
        React.createElement(FormInput, { type: "text", label: lastName.label, name: lastName.value, toolTip: lastName.toolTip, control: register, placeholder: lastName.placeholder, additionalToTooltip: lastName.additionalToTooltip, isRequired: lastName.required, error: errors && errors[lastName.value] ? errors[lastName.value] : undefined }),
        React.createElement(FormSelect, { label: gender.label, options: genderOptions, toolTip: gender.toolTip, placeholder: gender.placeholder, name: gender.value, control: control, spanTwoCols: gender.spanTwoColumns, additionalToTooltip: gender.additionalToTooltip, isRequired: gender.required, error: errors && errors[gender.value] ? errors[gender.value] : undefined }),
        React.createElement(FormDatePicker, { label: dateOfBirth.label, toolTip: dateOfBirth.toolTip, name: dateOfBirth.value, control: control, additionalToTooltip: dateOfBirth.additionalToTooltip, isRequired: dateOfBirth.required, error: errors && errors[dateOfBirth.value] ? errors[dateOfBirth.value] : undefined }),
        React.createElement(FormAutoComplete, { label: occupation.label, control: control, name: occupation.value, options: occupationOptions, isRequired: occupation.required, error: errors && errors[occupation.value] ? errors[occupation.value] : undefined }),
        React.createElement(FormSelect, { label: maritalStatus.label, options: maritalStatusOptions, toolTip: maritalStatus.toolTip, placeholder: maritalStatus.placeholder, name: maritalStatus.value, control: control, spanTwoCols: maritalStatus.spanTwoColumns, additionalToTooltip: maritalStatus.additionalToTooltip, isRequired: maritalStatus.required, error: errors && errors[maritalStatus.value] ? errors[maritalStatus.value] : undefined }),
        React.createElement(FormSelect, { label: phRelationship.label, options: phRelationshipOptions, toolTip: phRelationship.toolTip, placeholder: phRelationship.placeholder, name: phRelationship.value, control: control, spanTwoCols: phRelationship.spanTwoColumns, additionalToTooltip: phRelationship.additionalToTooltip, isRequired: phRelationship.required, error: errors && errors[phRelationship.value] ? errors[phRelationship.value] : undefined }),
        React.createElement(FormRadio, { label: ownYourOwnHome.label, toolTip: ownYourOwnHome.toolTip, options: ownYourOwnHome.options, name: ownYourOwnHome.value, control: control, additionalToTooltip: ownYourOwnHome.additionalToTooltip, isRequired: ownYourOwnHome.required, error: errors && errors[ownYourOwnHome.value] ? errors[ownYourOwnHome.value] : undefined }),
        React.createElement(FormRadio, { label: ukResident.label, toolTip: ukResident.toolTip, options: ukResident.options, name: ukResident.value, control: control, additionalToTooltip: ukResident.additionalToTooltip, isRequired: ukResident.required, error: errors && errors[ukResident.value] ? errors[ukResident.value] : undefined }),
        watchedFields.ukResident5Years === 'No' && (React.createElement(FormDateYearMonthOnly, { label: ukResidentSince.label, toolTip: ukResidentSince.toolTip, name: ukResidentSince.value, control: control, additionalToTooltip: phRelationship.additionalToTooltip, isRequired: phRelationship.required, error: errors && errors[ukResidentSince.value] ? errors[ukResidentSince.value] : undefined }))));
};
export default AddDriverNewDriver;
